import {createSelector} from "reselect";

const dejaClientSelector = state => state.dejaClient
const dejaClientListePersonnesSelector = state => state.dejaClient?.DemandeTarif?.ListePersonnes

export const getDejaClient = createSelector(
    dejaClientSelector,
    (dejaClient) => dejaClient,
);

export const getListePersonnes0DejaClient = createSelector(
    dejaClientListePersonnesSelector,
    (dejaClientLP) => dejaClientLP?.[0],
);