import { reset } from 'redux-form';
import { POST_ASK_QUESTION } from '../../../actions/app/askQuestion/askQuestion.actions';
import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import { apiRequest, API_ERROR, API_SUCCESS } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';

export const askQuestionMiddleware = (dispatch) => (next) => (action) => {
    next(action);

    const { payload } = action;

    switch (action.type) {
        case POST_ASK_QUESTION:
            next([
                apiRequest({ body: payload.body, method: 'POST', url: payload.data, entity: POST_ASK_QUESTION }),
                setLoader({ state: true, entity: POST_ASK_QUESTION }),
            ]);

            break;

        case `${POST_ASK_QUESTION} ${API_SUCCESS}`:
            next([
                setError({ state: false, entity: POST_ASK_QUESTION }),
                setLoader({ state: false, entity: POST_ASK_QUESTION }),
                setNotification({
                    entity: POST_ASK_QUESTION,
                    html: null,
                    type: 'success',
                    title: 'Merci, votre demande a bien été prise en envoyé.',
                }),
                reset('ask-question'),
            ]);

            break;

        case `${POST_ASK_QUESTION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_ASK_QUESTION,
                    html: payload.data?.response?.data?.error,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '10000',
                }),
                setError({ state: true, entity: POST_ASK_QUESTION }),
                setLoader({ state: false, entity: POST_ASK_QUESTION }),
            ]);
            break;

        default:
            return null;
    }
};
