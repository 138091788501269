import _ from 'lodash';
import { createSelector } from 'reselect';

/* Tarif */
const tarifSelector = (state) => state.tarif;

export const isLoadedTarif = createSelector(tarifSelector, (tarif) => !_.isEmpty(tarif));

export const getFeuTarif = createSelector(tarifSelector, (tarif) => tarif?.FeuTricolore);

/* Liste garanties par formule */
const formuleSelectedSelector = (state, code) => _.find(state.tarif.ListeFormules, ['CodeFormule', code]) || {};

/* Liste Formules */
export const getListeFormulesTarif = createSelector(
    tarifSelector,
    (tarif) => _.orderBy(tarif.ListeFormules, ['CodeFormule'], ['asc']) || [],
);

/* Formule Preconisee */
export const getCodeFormulePreconiseeTarif = createSelector(tarifSelector, (tarif) => tarif.FormulePreconisee);
export const getFormulePreconiseeTarif = createSelector(
    getListeFormulesTarif,
    getCodeFormulePreconiseeTarif,
    (liste, code) => _.find(liste, ['CodeFormule', code]),
);

/* CODE PROMO */
export const getInfoCodePromo = createSelector(tarifSelector, (tarif) => tarif.CodePromo);

/* URL PDF */
export const getUrlPDFConventionAssistanceTarif = createSelector(
    tarifSelector,
    (tarif) => tarif.UrlPDFConventionAssistance,
);

export const getUrlPDFDispositionsGeneralesTarif = createSelector(
    tarifSelector,
    (tarif) => tarif.UrlPDFDispositionsGenerales,
);

export const getUrlPDFDocumentInformationTarif = createSelector(
    tarifSelector,
    (tarif) => tarif.UrlPDFDocumentInformation,
);

export const getGarantiesInfoFranchiseDTA = createSelector(formuleSelectedSelector, (formule) =>
    _.filter(formule.ListeGaranties, { IdGroupeGarantie: 'DTA' }),
);

export const getGarantiesInfoFranchiseVI = createSelector(formuleSelectedSelector, (formule) =>
    _.filter(formule.ListeGaranties, { IdGroupeGarantie: 'VI' }),
);

export const getGarantiesOption = createSelector(formuleSelectedSelector, (formule) =>
    _.orderBy(
        _.filter(formule.ListeGaranties, { TypeGarantie: 'Option', Disponible: 'Option' }),
        ['OrdreAffichage'],
        ['asc'],
    ),
);

export const getFormuleGarantiesASS = createSelector(getGarantiesOption, (garanties) =>
    _.filter(garanties, { IdGroupeGarantie: 'ASS' }),
);

export const getPoliceTarif = createSelector(tarifSelector, (tarif) => tarif.Police || {});


export const getHonorairesTarifApporteurTarif = createSelector(getPoliceTarif, (police) =>
    police.Honoraires ? parseInt(police.Honoraires) : 0,
);

export const getTauxApporteurTarifApporteurTarif = createSelector(getPoliceTarif, (police) =>
    police.TauxApporteur1 ? parseInt(police.TauxApporteur1) : 0,
);

export const getFraisRecurrentsTarifApporteurTarif = createSelector(getPoliceTarif, (police) =>
    police.FraisRecurrents ? parseInt(police.FraisRecurrents) : 0,
);

export const getHonorairesMaxiApporteurTarif = createSelector(getPoliceTarif, (police) =>
    police.HonorairesMaxi ? police.HonorairesMaxi : 20,
);

export const getTauxApporteurMaxiApporteurTarif = createSelector(getPoliceTarif, (police) =>
    police.TauxApporteur1Maxi ? police.TauxApporteur1Maxi : 20,
);

export const getFraisRecurrentsMaxiApporteurTarif = createSelector(getPoliceTarif, (police) =>
    police.FraisRecurrentsMaxi ? police.FraisRecurrentsMaxi : 60,
);

export const getFormuleGarantiesPCC = createSelector(getGarantiesOption, (garanties) =>
    _.filter(garanties, { IdGroupeGarantie: 'PCC' }),
);

export const getFormuleGarantiesKSM = createSelector(getGarantiesOption, (garanties) =>
    _.filter(garanties, { IdGroupeGarantie: 'KSM' }),
);

export const getFormuleGarantiesVALMAJ = createSelector(getGarantiesOption, (garanties) =>
    _.filter(garanties, { IdGroupeGarantie: 'VALMAJ' }),
);

export const getFormuleGarantiesACCESSEQP = createSelector(getGarantiesOption, (garanties) =>
    _.filter(garanties, { IdGroupeGarantie: 'ACCESSEQP' }),
);

export const getFormuleFranchises = createSelector(getGarantiesOption, (garanties) =>
    _.filter(garanties, function (o) {
        return o.IdGroupeGarantie === 'RAFRAVI' || o.IdGroupeGarantie === 'RAFRADTA';
    }),
);

export const getFormuleGarantiesSelected = createSelector(formuleSelectedSelector, (formule) => {
    if (formule) {
        return _.filter(formule.ListeGaranties, function (garantie) {
            return garantie.TypeGarantie === 'Option' && garantie.Disponible === 'Option' && garantie.Souscrite;
        });
    }

    return null;
});

export const getFormuleLibelle = createSelector(formuleSelectedSelector, (formule) => formule.LibelleFormule);
