import _ from 'lodash';
import { Field } from 'redux-form';
import { errorOnQuad, onError, required } from '@amo/core/utils/validateField';
import {Radio} from "@amo/core/components/forms";

const TitulaireCarteGriseMajeur = (props) => {
    const { changeValue, conducteur, familleProduit, source, isDejaClient, isSociety } = props;

    return (
        <>
            {(!isDejaClient || !isSociety) &&
                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                    <Field
                        component={Radio}
                        name={'titulaireCarteGrise'}
                        value={'1'}
                        type={'radio'}
                        label={source === "courtier" ? "de votre client ou son conjoint" : "de vous ou votre conjoint(e)" }
                        validate={[required, onError]}
                        onChange={() => {
                            changeValue('DemandeTarif[ListePersonnes]', [{ ...conducteur }]);
                            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true);
                        }}
                        className={'text-center'}
                    />
                </div>
            }

            {(!_.includes(['quad', 'verte'], familleProduit) && (!isDejaClient || isSociety)) && (
                <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                    <Field
                        component={Radio}
                        name={'titulaireCarteGrise'}
                        value={'2'}
                        type={'radio'}
                        label={source === "courtier" ? "de la société dont il est le gérant ou le salarié" : "de la société dont vous êtes le gérant ou le salarié" }
                        validate={[required, onError, errorOnQuad]}
                        onChange={() => {
                            changeValue('DemandeTarif[ListePersonnes][1][TypePersonne]', 'M');
                            changeValue('DemandeTarif[ListePersonnes][1][Civilite]', '3');
                            changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
                            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
                        }}
                        className={'text-center'}
                    />
                </div>
            )}

            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                <Field
                    component={Radio}
                    name={'titulaireCarteGrise'}
                    value={'onError'}
                    type={'radio'}
                    label={'Autre'}
                    validate={[required, onError]}
                    className={'text-center'}
                />
            </div>
        </>
    );
};

export default TitulaireCarteGriseMajeur;
