import React from 'react';
import _ from "lodash";
import {getAddress} from "@amo/core/utils/index.js";
import questions from "../../../../../../../utils/json/questions.json";

const BoutonsAdresseDejaClient = (props) => {
    const {data, changeValue,hasSearchAddress, source, errorsForm} = props

    const postAdresseDejaClient = () => {
        if (!_.some(['Adresse1', 'CP', 'Ville'], (field) => errorsForm?.DejaClient?.[field])) {
            if (hasSearchAddress) {
                getAddress(_.get(data, 'DejaClient[Adresse1]'), 'ValidateForm');
            }

            changeValue('DejaClient.Validate', true);
            changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', _.get(data, 'DejaClient[Adresse1]'));
            changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', _.get(data, 'DejaClient[Adresse2]'));
            changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', _.get(data, 'DejaClient[Adresse3]'));
            changeValue('DemandeTarif[ListePersonnes][0][CP]', _.get(data, 'DejaClient[CP]'));
            changeValue('DemandeTarif[ListePersonnes][0][Ville]', _.get(data, 'DejaClient[Ville]'));
        }
    };

    return (
        <div className="row">
            <div className={'col-12 col-md-6 mt-2 mt-md-2'}>
                {_.get(data, 'DejaClient[Validate]') !== false ? (
                    <button
                        className="btn btn-primary mt-3 w-100"
                        onClick={() => changeValue('DejaClient.Validate', false)}
                    >
                        Modifier l'adresse postale
                    </button>
                ) : <>
                    <div className={'alert-info p-4 rounded text-left mt-3 f-14'}>
                        {questions['adresseDejaClient'][source]}
                    </div>
                    <button
                        className="btn btn-primary mt-3 w-100"
                        onClick={() => postAdresseDejaClient()}
                    >
                        Je valide ma nouvelle adresse
                    </button>
                </>}
            </div>
        </div>
    );
};

export default BoutonsAdresseDejaClient;