import { QuestionLabel, SimpleTitle } from '@amo/core/components/containers';
import {CAPAdresseTrottinetteVae, PostalCodeCity, Text} from '@amo/core/components/forms';
import {
    normalizeNumMarquage,
    normalizeTel,
} from '@amo/core/utils/normalize';
import { getAddress } from '@amo/core/utils/requestsApi';
import {
    excludeCodePostal,
    minLentghImmatriculation,
    required,
    validateEmail, validateImmatriculation,
    validatePhone,
    validateVIN,
} from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {Field} from 'redux-form';
import questions from '../../../../../../utils/json/questions.json';
import PopupDevisContainer from '../PopupDevis/PopupDevisContainer';
import PersonneFields from './PersonneFields/PersonneFields';
import { Checkbox } from '@amo/core/components/forms';
import './informations.scss'
import SIREN from './SIREN.jsx';
import moment from "moment";
import BoutonsAdresseDejaClient from "./BoutonsAdresseDejaClient/BoutonsAdresseDejaClient.jsx";

const InformationsConducteur = (props) => {
    const {
        data,
        email,
        emailValidation,
        emailOnError,
        saveDevisError,
        changeValue,
        postalCodeCity,
        conducteurEstSouscripteur,
        titulaireCarteGrise,
        VehiculeImmatricule,
        permis,
        handleSubmit,
        saveDevisLoading,
        fillLoading,
        devisOrSubscribe,
        previousStep,
        postSaveDevis,
        source,
        apporteur,
        apporteur1,
        ImmatCheck,
        dateNaissance,
        dejaClientData,
        immatriculationAlreadySet,
        searchBy
    } = props;

    const [showPopupDevis, setShowPopupDevis] = useState(false);
    const [hasSearchAddress, setHasSearchAddress] = useState(false);
    const [tryPost, setTryPost] = useState(false);
    const isDejaClient = !_.isEmpty(dejaClientData);
    const [immatDisabled, setImmatDisabled] = useState(immatriculationAlreadySet && searchBy === "immatriculation");

    const apporteurEmails = [apporteur?.emailComptabilite, apporteur?.emailGestion, apporteur?.emailPrincipal, apporteur?.emailSinistre, apporteur?.emailVente];
    const isEmailIsDifferentThenApporteur = !apporteurEmails.includes(email);

    useEffect(() => {
        if (tryPost) {
            isNotValid();
        }
    }, [data, tryPost]);

    useEffect(() => {
        changeValue('emailOnError', email && emailValidation && email !== emailValidation ? 'onError' : null);
    }, [email, emailValidation]);

    useEffect(() => {
        if (moment.isMoment(dateNaissance) && moment(dateNaissance).isValid() && moment(dateNaissance).add(18, 'years') > moment()){
            changeValue('DemandeTarif[ListePersonnes][1][Souscripteur]', true);
            changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', false);
        }
    }, []);

    const postSaveDevisAndShowModal = (form) => {
        setTryPost(true);
        if (isNotValid()) {
            document.getElementsByClassName('error')[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
            setShowPopupDevis(true);
            postSaveDevis(form);

            if (hasSearchAddress) {
                const address = _.get(form, 'DejaClient[Validate]') === true
                    ? _.get(form, 'DejaClient[Adresse1]')
                    : _.get(form, 'DemandeTarif[ListePersonnes][0][Adresse1]');
                getAddress(address, 'ValidateForm');
            }
        }

    };

    const handleError = (fieldName, remove) => {
        if (document.getElementsByName(fieldName).length > 0) {
            _.forEach(document.getElementsByName(fieldName), (input) => {
                if (input.getAttribute('type') === 'radio') {
                    if (remove) {
                        input.labels[0].classList.remove('error');
                    } else {
                        input.labels[0].classList.add('error');
                    }
                } else {
                    if (remove) {
                        input.classList.remove('error');
                    } else {
                        input.classList.add('error');
                    }
                }
            });
        } else {
            /* Gestion des autocomplete */
            if (remove) {
                document.querySelectorAll(`[data-name='${fieldName}']`)[0]?.classList.remove('error');
            } else {
                document.querySelectorAll(`[data-name='${fieldName}']`)[0]?.classList.add('error');
            }
        }
    };

    const isNotValid = () => {
        let notValid = false;
        let fields = [];

        if (titulaireCarteGrise && titulaireCarteGrise === '2'){
            fields.push('DemandeTarif[ListePersonnes][1][IdSociety]')
            fields.push(`DemandeTarif[ListePersonnes][1][Nom]`)
            fields.push('DemandeTarif[ListePersonnes][1][LegalStatus]')
        } else {
            if (conducteurEstSouscripteur === 'false') {
                fields.push(`DemandeTarif[ListePersonnes][1][Civilite]`)
                fields.push(`DemandeTarif[ListePersonnes][1][Nom]`)
                fields.push(`DemandeTarif[ListePersonnes][1][BirthName]`)
                fields.push(`DemandeTarif[ListePersonnes][1][Prenom]`)
                fields.push(`DemandeTarif[ListePersonnes][1][BirthCountry]`)
                fields.push(`DemandeTarif[ListePersonnes][1][BirthCity]`)
                fields.push(`DemandeTarif[ListePersonnes][0][Civilite]`)
                fields.push(`DemandeTarif[ListePersonnes][0][Nom]`)
                fields.push(`DemandeTarif[ListePersonnes][0][BirthName]`)
                fields.push(`DemandeTarif[ListePersonnes][0][Prenom]`)
                fields.push(`DemandeTarif[ListePersonnes][0][BirthCountry]`)
                fields.push(`DemandeTarif[ListePersonnes][0][BirthCity]`)
            } else {
                fields.push(`DemandeTarif[ListePersonnes][0][Civilite]`)
                fields.push(`DemandeTarif[ListePersonnes][0][Nom]`)
                fields.push(`DemandeTarif[ListePersonnes][0][BirthName]`)
                fields.push(`DemandeTarif[ListePersonnes][0][Prenom]`)
                fields.push(`DemandeTarif[ListePersonnes][0][BirthCountry]`)
                fields.push(`DemandeTarif[ListePersonnes][0][BirthCity]`)
            }
        }
        fields.push('DemandeTarif[ListePersonnes][0][Email]')

        if(isDejaClient) {
            fields.push('DejaClient[Ville]')
            fields.push('DejaClient[CP]')
            fields.push('DejaClient[Adresse1]')
        } else {
            fields.push('DemandeTarif[ListePersonnes][0][Ville]')
            fields.push('DemandeTarif[ListePersonnes][0][CP]')
            fields.push('DemandeTarif[ListePersonnes][0][Adresse1]')
        }

        if (!_.includes([479846, 111619, 479835], apporteur1.codeBelair)){
            fields.push('DemandeTarif[ListePersonnes][0][TelPortable]')
        }

        if (!emailValidation || emailOnError || emailOnError === 'onError'){
            fields.push('EmailValidation')
        }

        fields.forEach((field) => {
            if (!_.get(data, field) && field !== '') {
                handleError(field);
                notValid = true;
            } else {
                handleError(field, true);
            }
        });

        if(isDejaClient && _.get(data, 'DejaClient[Validate]') === false) {
            handleError('DejaClient[Adresse1]');
            notValid = true;
        }

        return notValid;
    };

    return (
        <div>
            <SimpleTitle>{questions['titreInformation'][source]}</SimpleTitle>

            {titulaireCarteGrise && titulaireCarteGrise === '2' ? (
                <SIREN changeValue={changeValue} source={source} isDejaClient={isDejaClient}/>
            ) : conducteurEstSouscripteur === 'false' ? (
                <PersonneFields data={data} personneIndex={1} {...props} changeValue={changeValue}/>
            ) : (
                <PersonneFields data={data} personneIndex={0} {...props} changeValue={changeValue}/>
            )}
            <hr/>
            <SimpleTitle className={'mt-5'}>{questions['titleAdressePostale'][source]}</SimpleTitle>
            <QuestionLabel className={'f-14 mt-4'} required>
                Ville / Code postal de l'adresse postale
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-2'} data-name="DemandeTarif[ListePersonnes][0][Ville]">
                    <PostalCodeCity
                        icon={'pin'}
                        id={isDejaClient ? 'dejaclientcodepostal' : 'coordonnees'}
                        nameVille={isDejaClient ? 'DejaClient[Ville]' : 'DemandeTarif[ListePersonnes][0][Ville]'}
                        nameCodePostal={isDejaClient ? 'DejaClient[CP]' : 'DemandeTarif[ListePersonnes][0][CP]'}
                        changeValue={changeValue}
                        validate={[excludeCodePostal]}
                        defaultValue={postalCodeCity}
                        placeholder={'Ex: 75011 PARIS'}
                        disabled={isDejaClient && _.get(data, 'DejaClient[Validate]') !== false}
                        required
                    />
                </div>
            </div>

            <CAPAdresseTrottinetteVae
                font={14}
                data={data}
                changeValue={changeValue}
                setHasSearchAddress={setHasSearchAddress}
                label={{
                    adresse1: questions['adressePostale'][source],
                    adresse2: questions['complementAdresse'][source],
                }}
                disabled={isDejaClient && _.get(data, 'DejaClient[Validate]') !== false}
                path={isDejaClient ? 'DejaClient' : 'DemandeTarif[ListePersonnes][0]'}
            />

            {isDejaClient && <BoutonsAdresseDejaClient hasSearchAddress={hasSearchAddress} {...props} />}
            
            {
                !_.includes([479846, 111619, 479835], apporteur1.codeBelair) && <>
                    <hr/>
                    <SimpleTitle className={'mt-5'}>Ses coordonnées</SimpleTitle>
                    <QuestionLabel className={'f-14 mt-4'} required>
                        {questions['numeroTel'][source]}&nbsp;
                    </QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][0][TelPortable]'}
                                icon={'phone'}
                                iconSize={18}
                                required
                                validate={[required, validatePhone]}
                                normalize={normalizeTel}
                                onChange={(e) => changeValue('DemandeContrat[SignaturePhone]', normalizeTel(e.target.value))}
                            />
                        </div>
                    </div>
                    <p className={'m-0 text-grey'}>
                        <small>{questions['usageTel'][source]}</small>
                    </p>
                </>
            }


            <QuestionLabel className={'f-14 mt-4'} required>
                {questions['emailClient'][source]}&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                    <Field
                        component={Text}
                        name={'DemandeTarif[ListePersonnes][0][Email]'}
                        icon={'envelope'}
                        validate={[required, validateEmail]}
                        iconSize={18}
                        onChange={(e) => changeValue('DemandeContrat[SignatureEmail]', e.target.value)}
                    />
                </div>
            </div>

            {source === 'courtier' && !isEmailIsDifferentThenApporteur && (
                <div className={'bg-warning p-4 rounded text-left mt-4 f-14'}>
                    <b>Attention, vous avez saisi l'adresse e-mail de votre cabinet et non celle de votre client.</b>
                    <p>
                        Avec l'arrêt de la carte verte et la dématérialisation des documents liés, nous avons besoin de l'adresse e-mail de votre client pour lui envoyer son avis d'échéance, en votre nom, autrement que par voie postale. Cette adresse ne sera pas utilisée à des fins commerciales.
                    </p>
                 </div>
            )}

            <QuestionLabel className={'f-14 mt-4'} required>
                {questions['confirmationEmailClient'][source]}&nbsp;
            </QuestionLabel>
            <div className={'row'}>
                <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                <Field
                        component={Text}
                        name={'EmailValidation'}
                        icon={'envelope'}
                        validate={[required, validateEmail]}
                        iconSize={18}
                    />
                </div>
            </div>

            <p className={'m-0 text-grey'}>
                <small>{questions['textEmailClient'][source]}</small>
            </p>

            {emailOnError && emailOnError === 'onError' && (
                <div className={'bg-danger p-4 rounded text-left mt-4 f-14'}>
                    <p className={'mb-0'}>Les deux adresses emails doivent être identiques.</p>
                </div>
            )}

            {conducteurEstSouscripteur === 'false' && (
                <>
                    <QuestionLabel>Le conducteur</QuestionLabel>
                    <PersonneFields data={data} personneIndex={0} changeValue={changeValue} {...props} />
                </>
            )}

            <hr/>
            <SimpleTitle className={'mt-5'}>Informations complémentaires</SimpleTitle>
            {_.find(permis, function (p) {
                return p && p.TypePermis;
            }) !== undefined && (
                <>
                    <QuestionLabel className={'f-14 mt-4'}>Quel est le N° de permis du conducteur</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[ListePersonnes][0][NumPermis]'}
                                normalize={(v) => _.trim(v.toUpperCase())}
                            />
                        </div>
                    </div>
                </>
            )}

            {VehiculeImmatricule === '0' || ImmatCheck === '0' ? (
                <>
                    <QuestionLabel className={'f-14 mt-4'}>Numéro de série / VIN (7 derniers caractères)</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[Vehicule][VIN]'}
                                placeholder={'EX: 49R8575'}
                                validate={[validateVIN]}
                                normalize={normalizeNumMarquage}
                            />
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12 col-md-12 mt-3 mt-md-2'}>
                            <Field
                                name="immatriculation_check"
                                component={Checkbox}
                                type={'checkbox'}
                                format={(v) => v === '0'}
                                normalize={(v) => (v ? '0' : '1')}
                                onChange={() => changeValue('DemandeTarif[Vehicule][VIN]', null)}
                            >
                                <div className={'f-14 mx-2'}>Le véhicule a une immatriculation</div>
                            </Field>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <QuestionLabel className={'f-14 mt-4'}>Quel est le N° d'immatriculation du véhicule</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-6 mt-3 mt-md-2'}>
                            <Field
                                name={`DemandeTarif[Vehicule][Immatriculation]`}
                                label={""}
                                placeholder={'EX: AA 123 BB'}
                                component={Text}
                                type={'text'}
                                typeFormat={'immat'}
                                validate={[minLentghImmatriculation, validateImmatriculation]}
                                disabled={immatDisabled}
                            />
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-12 col-md-12 mt-3 mt-md-2'}>
                            <Field
                                name="immatriculation_check"
                                component={Checkbox}
                                type={'checkbox'}
                                format={(v) => v === '0'}
                                normalize={(v) => (v ? '0' : '1')}
                                onChange={() => changeValue('DemandeTarif[Vehicule][Immatriculation]', null)}
                            >
                                <div className={'f-14 mx-2'}>Le véhicule n'a pas encore d'immatriculation</div>
                            </Field>
                        </div>
                    </div>
                </>
            )}

            <div className={'submit-row mt-5'}>
                <button
                    className={`btn btn-primary medium-rounded float-right mb-2 order-lg-1 text-uppercase`}
                    onClick={() => postSaveDevisAndShowModal(data)}
                    data-id="btn-next-info-step"
                >
                    {saveDevisLoading || fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>
                    ) : devisOrSubscribe === 'devis' ? (
                        'OBTENIR LA PROPOSITION'
                    ) : (
                        'Continuer'
                    )}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>

            {devisOrSubscribe === 'devis' && !saveDevisError && !saveDevisLoading && showPopupDevis && (
                <PopupDevisContainer {...props} />
            )}
        </div>
    );
};

export default InformationsConducteur;
