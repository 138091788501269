import trajet from '@amo/core/assets/images/AMO_Icons/Trajet.svg';
import { BulleAide, QuestionLabel, SimpleTitle } from '@amo/core/components/containers';
import { PostalCodeCity, Radio } from '@amo/core/components/forms';
import { excludeCodePostal, onError, required } from '@amo/core/utils/validateField';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import questions from '../../../../../../utils/json/questions.json';
import QuestionContentContainer from '../../QuestionContent/QuestionContentContainer';

const InformationsVehicule = (props) => {
    const {
        assure_3mois,
        changeValue,
        postalCodeCity,
        possessionPlusDe3Mois,
        carteGriseFrancaise,
        familleProduit,
        usage,
        cylindree,
        usageNonLivraison,
        situationVehicule,
        dateMEC,
        vehiculeImmatricule,
        valid,
        handleSubmit,
        nextStep,
        fillLoading,
        previousStep,
        source,
        dateAchat,
        links,
    } = props;
    const [dateMECMoins3mois, setDateMECMoins3mois] = useState(moment.isMoment(dateMEC) && moment(dateMEC)._isValid && moment().diff(dateMEC, 'months') < 3);

    useEffect(() => {
        if (!_.includes(['quad', 'verte'], familleProduit)) {
            changeValue('DemandeTarif[Vehicule][VehiculeImmatricule]', '1');
        }
        if (_.includes(['quad', 'verte'], familleProduit)) {
            changeValue('DemandeTarif[Vehicule][UsageVehicule]', '2');
        }
    }, []);

    useEffect(() => {
        setDateMECMoins3mois(moment.isMoment(dateMEC) && moment(dateMEC)._isValid && moment().diff(dateMEC, 'months') < 3);

        //si le véhicule a moins de 3 mois alors on considère qu'il a été assuré les 3 derniers mois
        if (moment.isMoment(dateMEC) && moment(dateMEC)._isValid && moment().diff(dateMEC, 'months') < 3) {
            changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1');
        }

        changeValue('immat_log.date_mec_reelle', moment(dateMEC, 'DD/MM/YYYY').format('DD/MM/YYYY'));
    }, [dateMEC]);

    useEffect(() => {
        if (moment(dateAchat, 'DD/MM/YYYY', true) >= moment().startOf('day').subtract(3, 'months')) {
            changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', '0');
            changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1');
        } else {
            changeValue('DemandeTarif[Vehicule][PossessionPlusDe3Mois]', '1');
            changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '');
        }
    }, [dateAchat]);

    const handleRedirectToLivraison = () => {
        if (usageNonLivraison === 'onError') {
            window.location.href = links['flotte'];
        }
    };

    return (
        <div>
            <SimpleTitle>{questions['titreUsage'][source]}</SimpleTitle>

            {/* champ caché depuis dateAchat !dateMECMoins3mois && (
                <QuestionContentContainer name={'DemandeTarif[Vehicule][PossessionPlusDe3Mois]'}>
                    <QuestionLabel required>{questions['possessionPlusDe3Mois'][source]}&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][PossessionPlusDe3Mois]'}
                                value={'1'}
                                type={'radio'}
                                label={'Depuis plus de 3 mois'}
                                validate={required}
                                onChange={() => changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '')}
                                className={'small'}
                            />
                        </div>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][PossessionPlusDe3Mois]'}
                                value={'0'}
                                type={'radio'}
                                label={'Depuis moins de 3 mois'}
                                validate={required}
                                onChange={() => changeValue('DemandeTarif[Vehicule][Assure3DerniersMois]', '1')}
                                className={'small'}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )*/}

            {!dateMECMoins3mois && possessionPlusDe3Mois === '1' && (
                <>
                    <QuestionContentContainer name={'DemandeTarif[Vehicule][Assure3DerniersMois]'}>
                        <QuestionLabel required>Au cours des trois derniers mois, ce véhicule était-il assuré&nbsp;?&nbsp;</QuestionLabel>
                        <div className={'row'}>
                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field component={Radio} name={'DemandeTarif[Vehicule][Assure3DerniersMois]'} value={'1'} type={'radio'} label={'Oui'} validate={required} className={'small'} />
                            </div>

                            <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                                <Field component={Radio} name={'DemandeTarif[Vehicule][Assure3DerniersMois]'} value={'0'} type={'radio'} label={'Non'} validate={required} className={'small'} />
                            </div>
                        </div>
                    </QuestionContentContainer>
                    {assure_3mois === '0' && (
                        <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>
                                <strong>Attention !</strong> Le véhicule n'a pas été assuré sur les trois derniers mois et seule la Formule Essentielle sera proposée.
                            </p>
                        </div>
                    )}
                </>
            )}

            {!_.includes(['quad', 'verte'], familleProduit) && (
                <QuestionContentContainer name={'DemandeTarif[Vehicule][UsageVehicule]'}>
                    <QuestionLabel required>{questions['usageVehicule'][source]}&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        {familleProduit === 'moto' && (
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field component={Radio} name={'DemandeTarif[Vehicule][UsageVehicule]'} label={'Promenade'} value={'1'} type={'radio'} validate={required} className={'small'} />
                            </div>
                        )}
                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][UsageVehicule]'}
                                label={'Promenade + Trajet travail'}
                                value={'2'}
                                type={'radio'}
                                validate={required}
                                className={'small'}
                            />
                        </div>

                        <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                            <Field component={Radio} name={'DemandeTarif[Vehicule][UsageVehicule]'} label={'Affaires'} value={'3'} type={'radio'} validate={required} className={'small'} />
                        </div>
                    </div>
                    {usage === '1' && (
                        <BulleAide
                            icon={trajet}
                            title={`<b>Promenade</b>`}
                            text={`<p>Le véhicule assuré est utilisé pour les déplacements limités à la vie privée hors trajet
                                domicile – travail.</p>`}
                            className={'col-lg-12 col-md-12 f-14 mt-3'}
                        />
                    )}
                    {usage === '2' && (
                        <BulleAide
                            icon={trajet}
                            title={`<b>Promenade + Trajet Travail</b>`}
                            text={`<p>Le véhicule assuré est utilisé pour des déplacements privés et professionnels, mais il
                            ne sert en aucun cas à des tournées régulières (livraison, dépôts, clientèle, agences,
                            succursales ou chantiers), à la location, au transport à titre onéreux de marchandises
                            ou de voyageurs, même à titre occasionnel.</p>`}
                            className={'col-lg-12 col-md-12 f-14 mt-3'}
                        />
                    )}
                    {usage === '3' && (
                        <BulleAide
                            icon={trajet}
                            title={`<b>Affaires</b>`}
                            text={`<p>Le véhicule assuré est utilisé pour les déplacements à caractère privé ou professionnel
                            y compris tournées régulières de clientèle, agences dépôts, succursales ou chantiers.</p>
                            <b>Il ne sert en aucun cas à la location ou au transport à titre onéreux de
                                marchandises (livraison, coursiers, etc…) ou de voyageurs (Moto Taxi ou autres),
                                même à titre occasionnel.</b>`}
                            className={'col-lg-12 col-md-12 f-14 mt-3'}
                        />
                    )}
                </QuestionContentContainer>
            )}

            {_.includes(['cyclo', 'scooter', '50'], familleProduit) && cylindree < 125 && (
                <QuestionContentContainer name={'DemandeTarif[Vehicule][UsageNonLivraison]'}>
                    <QuestionLabel required>Ce véhicule est-il destiné à un usage professionnel lié à une activité de restauration ou de livraison&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field component={Radio} name={'DemandeTarif[Vehicule][UsageNonLivraison]'} value={'1'} type={'radio'} label={'Non'} validate={required} className={'small'} />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][UsageNonLivraison]'}
                                value={'onError'}
                                type={'radio'}
                                label={'Oui'}
                                validate={[required]}
                                className={'small'}
                            />
                        </div>
                    </div>
                    {usageNonLivraison === 'onError' && (
                        <div className={'bg-warning p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>
                                Pour vous assurer sur un usage professionnel lié à une activité de restauration ou de livraison nous vous redirigeons vers l’offre dédiée à la livraison.
                            </p>
                        </div>
                    )}
                </QuestionContentContainer>
            )}

            {/*            {_.includes(['verte'], familleProduit) && (
                <QuestionContentContainer name={'DemandeTarif[Vehicule][ValeurVehicule]'}>
                    <QuestionLabel required>Quelle est la valeur de votre véhicule&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Text}
                                name={'DemandeTarif[Vehicule][ValeurVehicule]'}
                                validate={[required, maxValeurVehicule]}
                                normalize={normalizeNumber}
                            />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}*/}

            {_.includes(['quad', 'verte'], familleProduit) && (
                <QuestionContentContainer name={'DemandeTarif[Vehicule][VehiculeImmatricule]'}>
                    <QuestionLabel required>Est-il immatriculé&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field component={Radio} name={'DemandeTarif[Vehicule][VehiculeImmatricule]'} value={'1'} type={'radio'} label={'Oui'} validate={required} className={'small'} />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field component={Radio} name={'DemandeTarif[Vehicule][VehiculeImmatricule]'} value={'0'} type={'radio'} label={'Non'} className={'small'} validate={[required, onError]} />
                        </div>
                    </div>
                </QuestionContentContainer>
            )}

            {vehiculeImmatricule !== '0' && (
                <QuestionContentContainer name={'DemandeTarif[Vehicule][CarteGriseFrancaise]'}>
                    <QuestionLabel required>La carte grise est...&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field component={Radio} name={'DemandeTarif[Vehicule][CarteGriseFrancaise]'} value={'1'} type={'radio'} label={'Française'} className={'small'} validate={required} />
                        </div>

                        <div className={'col-6 text-left col-md-4 mt-3 mt-md-2'}>
                            <Field
                                component={Radio}
                                name={'DemandeTarif[Vehicule][CarteGriseFrancaise]'}
                                value={'onError'}
                                type={'radio'}
                                label={'Étrangère'}
                                validate={[required, onError]}
                                className={'small'}
                            />
                        </div>
                    </div>
                    {carteGriseFrancaise === 'onError' && (
                        <div className={'bg-danger p-4 rounded text-left mt-5 f-14'}>
                            <p className={'mb-0'}>Désolé, nous n'assurons pas les véhicules possédant une carte grise étrangère.</p>
                        </div>
                    )}
                </QuestionContentContainer>
            )}

            <QuestionContentContainer name={'DemandeTarif[Vehicule][VilleGarage]'}>
                <QuestionLabel required>Quel est le code postal du lieu de stationnement&nbsp;?&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                        <PostalCodeCity
                            icon={'pin'}
                            id={'lieuStationnement'}
                            nameVille={'DemandeTarif[Vehicule][VilleGarage]'}
                            changeValue={changeValue}
                            nameCodePostal={'DemandeTarif[Vehicule][CodePostalGarage]'}
                            validate={[required, excludeCodePostal]}
                            placeholder={'Exemple : 75011 PARIS'}
                            defaultValue={postalCodeCity}
                            required
                        />
                    </div>
                </div>
            </QuestionContentContainer>

            <div className={'submit-row mt-5'}>
                <button
                    className={`${!valid ? 'disabled' : ''} btn btn-primary float-right medium-rounded mb-2 order-lg-1`}
                    onClick={valid ? (usageNonLivraison === 'onError' ? () => handleRedirectToLivraison() : handleSubmit(nextStep)) : null}
                    data-id="btn-next"
                >
                    {fillLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" /> : 'Continuer'}
                </button>

                <button className={`btn btn-outline-primary medium-rounded order-lg-0`} onClick={() => previousStep()}>
                    Retour
                </button>
            </div>
            <div className="row">
                <p className={'mt-4 text-grey'}>
                    <small>Merci de vérifier si tous les champs du formulaire ont bien été saisis.</small>
                </p>
            </div>
        </div>
    );
};

InformationsVehicule.defaultProps = {};

InformationsVehicule.propTypes = {};

export default InformationsVehicule;
