import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { demandeTarifReducer } from '../reducers/demandeTarif/demandeTarif.reducer';
import { initReducer } from '../reducers/init/init.reducer';
import { tarifReducer } from '../reducers/tarif/tarif.reducer';
import { uiReducer } from '../reducers/ui/ui.reducer';
import { vehiculeReducer } from '../reducers/vehicule/vehicule.reducer';
import {dejaClientReducer} from "../reducers/dejaClient/dejaClient.reducer.js";

const AppReducer = combineReducers({
    form,
    ui: uiReducer,
    init: initReducer,
    demandeTarif: demandeTarifReducer,
    tarif: tarifReducer,
    vehicule: vehiculeReducer,
    dejaClient: dejaClientReducer
});

export default AppReducer;
