import { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import {
    maxDateToday,
    minDateMEC,
    minLentghImmatriculation,
    required,
    validateImmatriculation
} from '@amo/core/utils/validateField';
import { BulleAide, Modal, QuestionLabel, SimpleTitle } from "@amo/core/components/containers";
import SearchBarVehiculeContainer from '../../Vehiculier/SearchBarVehicule/SearchBarVehiculeContainer';
import VehiculierContainer from '../../Vehiculier/VehiculierContainer';
import BlocInformationsContainer from './BlocInformations/BlocInformationsContainer';
import questions from '../../../../../../utils/json/questions.json'
import moment from "moment";
import CodePromo from '../../../../../../assets/images/code-promo-janv-25.png'
import { DateField, Radio, Text } from "@amo/core/components/forms";
import moto from "@amo/core/assets/images/produits/icon_moto.svg";
import scooter from "@amo/core/assets/images/produits/icon_scooter.svg";
import quad from "@amo/core/assets/images/produits/icon_quad.svg";
import motoverte from "@amo/core/assets/images/produits/icon_cross.svg";
import warning from "@amo/core/assets/images/icons/warning.svg";
import _ from "lodash";
import classNames from "classnames";
import Swal from "sweetalert2";
import {useImmatWheels} from "@amo/core/api/wheels.js";

const Vehicule = (props) => {
    const {
        vehiculeIsDisabled,
        marque,
        modele,
        cylindree,
        familleProduit,
        changeValue,
        postDejaClient,
        loadingDejaClient,
        handleSubmit,
        nextStep,
        fillLoading,
        valid,
        source,
        dejaClientValide,
        dejaClientNom,
        dejaClientPrenom,
        dejaClientBirthdate,
        isCourtier,
        apporteur1,
        apporteur2,
        sra,
        dejaClientRefresh,
        searchImmat,
        searchBy,
        setVehicule
    } = props;

    const [vehiculierAffiche, setVehiculierAffiche] = useState(false);
    const [previousVehicule, setPreviousVehicule] = useState(familleProduit);

    const [showClientConfirmation, setShowClientConfirmation] = useState(false);
    const [isClientConfirmed, setIsClientConfirmed] = useState(null);
    const [a2filter, setA2filter] = useState("1");

    const validImmat = !minLentghImmatriculation(searchImmat) && !validateImmatriculation(searchImmat) && !!searchImmat;
    const { data: responseVehicles, isLoading, isFetched } = useImmatWheels(validImmat, familleProduit, searchImmat, a2filter);
    const dataVehicles = responseVehicles?.data?.vehicles ?? [];
    const dataFiltered = responseVehicles?.data?.filtered ?? false;

    const refreshSearch = () => {
        setA2filter("0");
    };

    const setSelectedVehicle = (vehicle) => {
        changeValue('searchBy', 'immatriculation');
        setVehicule({data: vehicle})
        if (vehicle.souscription_disabled) {
            Swal.fire({
                icon: 'warning',
                iconHtml: '<span class="warning"></span>',
                title: 'Oups... Erreur de trajectoire',
                text: 'Malheureusement, nous ne pouvons pas vous proposer d’assurance pour ce type de véhicule. Vous pouvez reprendre votre demande de devis en modifiant votre réponse.',
                showConfirmButton: true,
                confirmButtonText: 'Terminer',
                showCloseButton: true,
            });
        } else {
            changeValue(`code_categorie`, vehicle.codecategorie);
            changeValue('DemandeTarif[Vehicule][IdentifiantSRA]', vehicle.sra);
            changeValue('DemandeTarif[Vehicule][DateMEC]', moment(responseVehicles?.data.date_premiere_immat, 'YYYY-MM-DD').format('DD/MM/YYYY'));
            changeValue('DemandeTarif[Vehicule][ValeurVehicule]', vehicle.prix_origine);
            changeValue('DemandeTarif[Vehicule][Immatriculation]', searchImmat);

            changeValue(`modeleVehicule`, vehicle.modele);
            changeValue(`marqueVehicule`, vehicle.brand.name);
            changeValue(`cylindreeVehicule`, vehicle.cylindree);
            changeValue('immat_log.has_select_immat', true);
            changeValue('typeVehicule', vehicle.type.id);
        }
    };

    useEffect(() => {
        if (isFetched) {
            changeValue('immat_log.has_search_immat', true);
            changeValue('immat_log.has_same_stats', responseVehicles?.data?.has_same_stats);
            changeValue('immat_log.search_immat_result_count', dataVehicles.length);
            changeValue('immat_log.date_mec_siv', moment(responseVehicles?.data?.date_premiere_immat, 'YYYY-MM-DD').format('DD/MM/YYYY'));
        }
    }, [isFetched]);

    const handleClientIdentification = async () => {
        setIsClientConfirmed(null);
        changeValue('clientConfirmation', null)
        const result = await postDejaClient();
        if (result) {
            setShowClientConfirmation(true);
        }
    };

    const handleClientConfirmation = (value) => {
        setIsClientConfirmed(value);

        if (value === '0') {
            resetClientData();
            setShowClientConfirmation(false); // Masquer la confirmation de client
            changeValue('dejaClientValide', false); // Réinitialiser le flag de validation
            Swal.fire({
                icon: 'info',
                confirmButtonText: "J'ai bien compris",
                html: '<p>Pour information, suite à votre réponse négative concernant l’identification du client, nous vous informons que nous vous présenterons un formulaire vide sans aucune information client.</p>',
            });
        }
    };

    const resetClientData = () => {
        changeValue('DemandeTarif[ListePersonnes][0]', {});

        changeValue('DemandeTarif[ListePersonnes][0][Adresse1]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Adresse2]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Adresse3]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthCity]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthCountry]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthDepartment]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][BirthName]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][CP]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Civilite]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][ConducteurMineur]', false);
        changeValue('DemandeTarif[ListePersonnes][0][CrmAuto50Plus3Ans]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][CrmMoto50Plus3Ans]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][DateNaissance]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][DejaClientAntecedents]', []);
        changeValue('DemandeTarif[ListePersonnes][0][Email]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][ListePermis]', null);
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceAuto]', null);
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisAssuranceMoto]', null);
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceAuto]', "24");
        changeValue('DemandeTarif[ListePersonnes][0][NbMoisReferenceMoto]', "24");
        changeValue('DemandeTarif[ListePersonnes][0][Nom]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][NumPermis]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][Prenom]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][RoleConducteur]', "P");
        changeValue('DemandeTarif[ListePersonnes][0][Souscripteur]', true);
        changeValue('DemandeTarif[ListePersonnes][0][TelPortable]', undefined);
        changeValue('DemandeTarif[ListePersonnes][0][TypePersonne]', "P");
        changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmAuto]', null);
        changeValue('DemandeTarif[ListePersonnes][0][ValeurCrmMoto]', null);
        changeValue('DemandeTarif[ListePersonnes][0][Ville]', undefined);

        // Supprimer les informations client confirmées
        changeValue('clientConfirmation', '');
        changeValue('dejaClientValide', false);
        changeValue('dejaClientNom', '');
        changeValue('dejaClientPrenom', '');
        changeValue('dejaClientBirthdate', '');
    };
    // Condition d'affichage Banniere
    const dateStartIsPassed = moment('2025-01-23') < moment(); // Date de début Jour J
    const dateIsPassed = moment('2025-02-01') < moment() // Date de fin J + 1
    const productIsAccepted = _.includes(['moto', 'scooter', '50'], familleProduit)

    const changeVehicule = (value) => {
        if (value !== previousVehicule) {
            setPreviousVehicule(value);
            changeValue('marqueIdVehicule', '');
            changeValue('marqueVehicule', '');
            changeValue('modeleVehicule', '');
            changeValue('code_categorie', '');
            changeValue('DemandeTarif[Vehicule][UsageVehicule]', null);

            if (value === '50') {
                changeValue('cylindreeVehicule', 50);
            } else {
                changeValue('cylindreeVehicule', '');
            }
            dejaClientRefresh(value);
        }
    };

    const isActifProduct = (name) => {
        return source !== 'site' ? _.find(apporteur1.produits, function (p) {
            return p.name === name && p.actif;
        }) !== undefined : true;
    };

    return (
        <>
            {/* Deja Client Section */}
            {source !== 'site' &&
                <BulleAide
                    icon={warning}
                    title={`<b>Nouvelle règlementation du démarchage téléphonique</b>`}
                    text={`<p>Dans le cadre de la loi sur le démarchage téléphonique, entrée en vigueur au 1er avril 2022,
                        nous vous accompagnons dans l’utilisation de nos outils pour vous permettre de remplir vos
                        obligations. Consultez notre rubrique dédiée en
                        <a href={'https://www.april-on.fr/kiosk/article/evolution-du-demarchage-telephonique-dans-le-cadre-de-prospects-froids'}
                            download  target={'_blank'} rel={'noreferrer noopener'}>
                            cliquant-ici
                        </a></p>`}
                    className={'col-lg-12 col-md-12 f-14'}
                />
            }
            { (apporteur1.codeBelair !== 479846 && apporteur1.codeBelair !== 111619 && apporteur1.codeBelair !== 479835) &&
                <>
                    <hr className={'mt-5'}/>
                    <div className={'mb-5'}>
                        <div className="mt-3">
                            <QuestionLabel>{questions['numeroClient'][source]}&nbsp;:</QuestionLabel>

                            {
                                isCourtier && <BulleAide
                                    text={`<p>Le prospect est déjà client et souhaite assurer un véhicule supplémentaire ? <br/>Renseignez le numéro de son contrat actuel ou le numéro client afin que nous puissions l’identifier.</p>`}
                                    className={'col-lg-12 col-md-12 f-14 my-3'}
                                />
                            }

                            <div className="row">
                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <Field
                                        component={Text}
                                        name={`DemandeTarif[ListePersonnes][0][NumeroClient]`}
                                        placeholder={'N° de contrat / N° de client'}
                                        disabled={dejaClientValide}
                                    />
                                </div>
                                <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                    <button
                                        className={`btn btn-primary medium-rounded mb-2 text-uppercase ${dejaClientValide && 'disabled'} btn-block`}
                                        onClick={handleClientIdentification}
                                        disabled={dejaClientValide}
                                    >
                                        {loadingDejaClient ? (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            'identifier'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>

                        {dejaClientValide && isClientConfirmed !== '0' && (
                            <div className="mt-3">
                                Nous avons bien identifié le client : <strong>{dejaClientNom} {dejaClientPrenom}</strong>{moment(dejaClientBirthdate, 'DD/MM/YYYY').isValid() && (<> , né(e) le <strong>{moment(dejaClientBirthdate, 'DD/MM/YYYY').format('DD/MM/YYYY')}</strong></>)}.
                                <QuestionLabel>Est-ce bien lui/elle&nbsp;?</QuestionLabel>
                                <div className="row">
                                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'clientConfirmation'}
                                            value={'1'}
                                            type={'radio'}
                                            label={'Oui'}
                                            onChange={() => handleClientConfirmation('1')}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                    <div className={'col-12 col-md-4 mt-2 mt-md-2'}>
                                        <Field
                                            component={Radio}
                                            name={'clientConfirmation'}
                                            value={'0'}
                                            type={'radio'}
                                            label={'Non'}
                                            onChange={() => handleClientConfirmation('0')}
                                            validate={required}
                                            className={'small'}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </>
            }
            <hr className={'mt-5 mb-5'}/>
            <div>
                <SimpleTitle>{questions['titreVehicule'][source]}</SimpleTitle>
                {!(apporteur1.codeBelair === 323284 || apporteur2?.codeBelair === 864160) && <>
                    <QuestionLabel required>{questions['typeVehicule'][source]}&nbsp;?&nbsp;</QuestionLabel>
                    <div className={'row'}>
                        {isActifProduct('MotoAMO') && (
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'typeVehicule'}
                                    value={'moto'}
                                    type={'radio'}
                                    label={'Moto'}
                                    icon={moto}
                                    validate={required}
                                    onChange={(e) => changeVehicule(e.target.value)}
                                    className={'text-center f-14'}
                                />
                            </div>
                        )}
                        {isActifProduct('MotoAMO') && (
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'typeVehicule'}
                                    value={'scooter'}
                                    type={'radio'}
                                    label={'Scooter'}
                                    icon={scooter}
                                    validate={required}
                                    onChange={(e) => changeVehicule(e.target.value)}
                                    className={'text-center f-14'}
                                />
                            </div>
                        )}
                        {isActifProduct('ScooterAMO') && (
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'typeVehicule'}
                                    value={'50'}
                                    type={'radio'}
                                    label={'Cyclo 50 cm3'}
                                    icon={scooter}
                                    validate={required}
                                    onChange={(e) => changeVehicule(e.target.value)}
                                    className={'text-center f-14'}
                                />
                            </div>
                        )}
                        {isActifProduct('QuadAMO') && (
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'typeVehicule'}
                                    value={'quad'}
                                    type={'radio'}
                                    label={'Quad'}
                                    icon={quad}
                                    validate={required}
                                    onChange={(e) => changeVehicule(e.target.value)}
                                    className={'text-center f-14'}
                                />
                            </div>
                        )}
                        {isActifProduct('QuadAMO') && (
                            <div className={'col-6 col-md-4 mt-3 mt-md-2'}>
                                <Field
                                    component={Radio}
                                    name={'typeVehicule'}
                                    value={'verte'}
                                    type={'radio'}
                                    label={'Moto verte'}
                                    icon={motoverte}
                                    validate={required}
                                    onChange={(e) => changeVehicule(e.target.value)}
                                    className={'text-center f-14'}
                                />
                            </div>
                        )}
                    </div>
                </>}

                {
                    // Banniere
                     !dateIsPassed && dateStartIsPassed && productIsAccepted &&
                    apporteur1.codeBelair !== 479846 && apporteur1.codeBelair !== 479835 && <div className={'mt-5'}>
                        <img src={CodePromo} alt="Code Promo" />
                    </div>
                }

                { searchBy === "immatriculation" ? <QuestionLabel required>{questions['immatVehicule'][source]}&nbsp;?&nbsp;</QuestionLabel> : <QuestionLabel required>{questions['modeleVehicule'][source]}&nbsp;?&nbsp;</QuestionLabel> }


                {
                    searchBy === "immatriculation" ? <div className={'mt-2'}>
                        <Field
                            name={`searchImmat`}
                            label={""}
                            placeholder={'EX: AA 123 BB'}
                            component={Text}
                            type={'text'}
                            typeFormat={'immat'}
                            validate={[minLentghImmatriculation, validateImmatriculation]}
                            required={required}
                        />
                        {isLoading &&
                            <span className="mt-2 mx-auto d-block spinner-border spinner-border-sm" role="status"
                                  aria-hidden="true"/>}
                        {dataVehicles.length > 0 && isFetched ? (
                            <div className="mt-4">
                                {dataVehicles.map((vehicle, index) => (
                                    <Field
                                        key={vehicle.sra}
                                        type={'radio'}
                                        className={`small option w-full`}
                                        label={`${vehicle.brand.name} ${vehicle.modele} ${vehicle.version}`}
                                        icon={vehicle.brand.img}
                                        value={vehicle.sra}
                                        description={`${vehicle.annee_debut_version !== null && vehicle.annee_debut_version !== 0 ? `depuis ${vehicle.annee_debut_version}` : ''} ${vehicle.annee_fin_version !== null && vehicle.annee_fin_version !== 0 ? ` jusqu'à ${vehicle.annee_fin_version}` : ''} ${vehicle.annee_fin_version || vehicle.annee_debut_version ? '-' : ''} ${vehicle.cylindree} cm3`}
                                        name={'selectVehicle'}
                                        onChange={(e) => setSelectedVehicle(vehicle)}
                                        component={Radio}
                                        validate={required}
                                    />
                                ))}
                            </div>
                        ) : isFetched && validImmat ? (
                            <div className={'alert-danger alert mt-1 mb-0'}>
                                Aucun modèle n'a été trouvé avec votre immatriculation.
                            </div>
                        ) : null}
                        {
                            dataFiltered &&
                            <span className="d-block text-secondary f-13 mt-1">
                            <p className="underline-link cursor-pointer" onClick={refreshSearch} >Voir d'autres versions (A2, modèle...) pour ce véhicule</p>
                        </span>
                        }
                        <span
                            type="button"
                            className={`mt-4`}
                            style={{cursor: 'pointer', textDecoration: 'underline'}}
                            onClick={() => {
                                changeValue('searchBy', 'modele');
                                changeValue('immat_log.has_search_immat', false);
                                changeValue('immat_log.has_select_immat', false);
                                changeValue('immat_log.has_same_stats', false);
                                changeValue('immat_log.date_mec_siv', null);
                                changeValue('immat_log.search_immat_result_count', 0);
                            }}>Je préfère rechercher par marque et modèle.</span>
                    </div> : <div className="row" style={{alignItems: 'flex-start'}}>
                        {modele && (
                            <div className={'mt-3 font-weight-bold text-primary-light f-14 col-12'}>
                                <span className={'text-initial'}>Son véhicule :</span>{' '}
                                <span dangerouslySetInnerHTML={{__html: marque}}/>{' '}
                                <span dangerouslySetInnerHTML={{__html: cylindree}}/>{' '}
                                <span dangerouslySetInnerHTML={{__html: modele}}/>
                            </div>
                        )}
                        <div className={'col-12 col-md-7 mt-3 mt-md-2'}>
                            <Radio
                                label={!modele ? 'Sélectionnez votre modèle' : 'Modifiez votre modèle'}
                                type={'checkbox'}
                                input={{
                                    onChange: () => setVehiculierAffiche(!vehiculierAffiche),
                                    checked: !!marque,
                                }}
                                className={'small'}
                            />
                        </div>
                        {
                            (!(apporteur1.codeBelair === '323284' || apporteur2?.codeBelair === '864160') && familleProduit) &&
                            <SearchBarVehiculeContainer/>
                        }
                        <div className="col-12">
                            <span
                                type="button"
                                className={`my-4`}
                                style={{cursor: 'pointer', textDecoration: 'underline'}}
                                onClick={() => {
                                    changeValue('searchBy', 'immatriculation');
                                }}>Je préfère rechercher par immatriculation.</span>
                        </div>
                    </div>
                }

                {vehiculierAffiche && (
                    <Modal
                        bodyScrollable
                        onClick={() => {
                            setVehiculierAffiche(!vehiculierAffiche);
                        }}
                        large
                        title={'Sélectionnez votre véhicule'}
                    >
                        <VehiculierContainer
                            inputName={'DemandeTarif[Vehicule]'}
                            setVehiculierAffiche={setVehiculierAffiche}
                        />
                    </Modal>
                )}

                {vehiculeIsDisabled && (
                    <div className={'bg-danger p-4 rounded text-left mt-2 f-14'}>
                        <p
                            className={'m-0'}
                            dangerouslySetInnerHTML={{
                                __html: `Attention, nous n'assurons pas ce type de véhicule. (${marque} ${cylindree} ${modele})`,
                            }}
                        />
                    </div>
                )}

                {!vehiculeIsDisabled && <BlocInformationsContainer cylindree={cylindree}/>}

                <QuestionLabel required>{questions['dateMEC'][source]}&nbsp;?&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={DateField}
                            name={'DemandeTarif[Vehicule][DateMEC]'}
                            label={'JJ/MM/AAAA'}
                            validate={[minDateMEC, maxDateToday, required]}
                            maxDate={moment()}
                            minDate={moment('01/01/1990')}
                        />
                    </div>
                </div>

                <QuestionLabel required>{questions['dateAchat'][source]}&nbsp;?&nbsp;</QuestionLabel>
                <div className={'row'}>
                    <div className={'col-12 col-md-4 mt-3 mt-md-2'}>
                        <Field
                            component={DateField}
                            name={'DemandeTarif[Vehicule][DateAchatVehicule]'}
                            label={'JJ/MM/AAAA'}
                            validate={[required]}
                            minDate={moment('01/01/1990')}
                        />
                    </div>
                </div>

                <BulleAide
                    title={"Comment renseigner la date d’acquisition&nbsp;?"}
                    text={'La date d\'acquisition correspond à la date à laquelle votre client a pris possession du véhicule.<br/>S’il n’est pas encore en possession de votre véhicule, indiquez la date d’aujourd’hui.'}
                    className={'col-lg-12 col-md-12 f-14 mt-4'}
                />
            </div>

            <div className={'submit-row mt-5 text-right'}>
                <button
                    className={classNames(
                        (!sra || !valid || vehiculeIsDisabled || (dejaClientValide && isClientConfirmed === '0')) && 'disabled',
                        'btn btn-primary medium-rounded mb-2 order-lg-1',
                    )}
                    onClick={(!sra || !valid || vehiculeIsDisabled || (dejaClientValide && isClientConfirmed === '0')) ? null : handleSubmit(nextStep)}
                    data-id="btn-next"
                >
                    {fillLoading ? (
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    ) : (
                        'Continuer'
                    )}
                </button>
            </div>
        </>
    );
};

export default Vehicule;
