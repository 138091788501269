import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Recontact from "./Recontact";
import {change, clearFields, formValueSelector, getFormSyncErrors, getFormValues} from "redux-form";
import _ from "lodash";
import {
    getPersonnePrincipaleIsSouscripteurDemandeTarifInit, getSourceDemandeTarifInit,
    getState
} from "../../../../../../redux/selectors/init/init.selectors";
import {getDevisOrSubscribe, getListCountries} from "../../../../../../redux/selectors/ui/ui.selectors";
import {setCountries} from "../../../../../../redux/actions/app/ui/ui.actions.js";
import {getDejaClient} from "../../../../../../redux/selectors/dejaClient/dejaClient.selectors.js";

const mapStateToProps = state => {
    const selector = formValueSelector('devis')
    const ville = selector(state, 'DemandeTarif[ListePersonnes][0][Ville]')
    const cp = selector(state, 'DemandeTarif[ListePersonnes][0][CP]')
    const ListePersonnes = selector(state, 'DemandeTarif[ListePersonnes]')
    const conducteurPrincipal = ListePersonnes ? _.find(ListePersonnes, {'TypePersonne': 'P', 'RoleConducteur': 'P'}) : {}

    return {
        source: getSourceDemandeTarifInit(state),
        data: getFormValues('devis')(state),
        errorsForm: getFormSyncErrors('devis')(state),
        postalCodeCity: ville ? `${ville} ${cp}` : '',
        conducteurEstSouscripteur: conducteurPrincipal ? String(conducteurPrincipal.Souscripteur) : getPersonnePrincipaleIsSouscripteurDemandeTarifInit(state),
        titulaireCarteGrise: selector(state, 'titulaireCarteGrise'),
        isDevisOrange: getState(state) === 'devis_orange',
        devisOrSubscribe: getDevisOrSubscribe(state),
        countries: getListCountries(state),
        birthCountry: selector(state, 'DemandeTarif[ListePersonnes][0][BirthCountry]'),
        dejaClientData: getDejaClient(state)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('devis', field, value),
    unsetValue: (field) => clearFields('devis', false, false, field),
    setCountries,
}, dispatch)


const RecontactContainer = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(Recontact)


export default RecontactContainer
